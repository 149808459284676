// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import Tabs from 'antd/lib/tabs'
import '@bodhi-project/antrd/lib/4.10.3/tabs/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ContactForm from '../components/contact-form'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Contact Us',
  nakedPageSlug: 'contact',
  pageAbstract:
    'LiGHT-FiSH is an emerging Product Design studio focused on the minimalistic beauty of natural materials. Sustainable materials are carefully chosen and crafted into visionary and intelligently designed products. Many custom and limited edition designs are prototyped, tested and refined, each time telling a unique story. Much like the stages of growth of a plant, from seed to tree, LiGHT-FiSH chooses a design process that is organic and systematic.',
}

const seoData = seoHelper(pageData)
const { TabPane } = Tabs

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    toolsBg: file(relativePath: { eq: "contact/img1.jpg" }) {
      ...max1600
    }
  }
`

const formRef = React.createRef()

const formConf = {
  macroId:
    'AKfycbx8tviWmyZFMgWCys-UPwmcBnhWDJ3MVjaWqiGGj0nx-roGhRkXYNKNrNKoxQS5adnE',
  includeTitle: true,
  includeExtra: true,
  fields: {
    name: {
      title: 'Name',
      extra: 'Required',
    },
    email: {
      title: 'Email',
      extra: 'Required',
    },
    phone: {
      title: 'Phone',
      extra: 'Required',
    },
    comment: {
      title: 'Comment',
      extra: 'How can we help you today?',
      rules: [
        {
          validator: (_, value) => {
            return Promise.resolve()
          },
        },
      ],
    },
  },
  trace: {
    success:
      'Thank you for your request. We have recieved your message, and will get back to you shortly.',
  },
}

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper className="contact-page" seoData={seoData} {...props}>
      <h1 style={{ textAlign: 'center' }}>Let's Talk</h1>
      <ContactForm refs={{ formRef }} conf={{ formConf }} />
      {/* <Tabs defaultActiveKey="1">
        <TabPane tab="Contact" key="1">
          <ContactForm refs={{ formRef }} />
        </TabPane>
        <TabPane tab="Support" key="2">
          Content of Tab Pane 2
        </TabPane>
        <TabPane tab="Volunteer Application" key="3">
          Content of Tab Pane 3
        </TabPane>
      </Tabs> */}
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
